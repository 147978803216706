import * as R from 'ramda';
import { put, spawn, takeLatest, select } from 'redux-saga/effects';
// helpers/constants
import * as G from '../../../helpers';
// common idb
import * as A from './actions';
import { makeSelectIDB, makeSelectIDBProps, makeSelectOrderTable } from './selectors';
//////////////////////////////////////////////////

function* onCloseDatabase(onClosePromise: Promise) {
  yield onClosePromise;

  yield put(A.reportTableOrderIDBReconnect());
  yield put(A.resetReportTableOrder());
}

export function* initTableOrderIDB() {
  let onIDBCloseResolved;

  const onClosePromise = new Promise((res: Function) => {
    onIDBCloseResolved = res;
  });

  const idbProps = yield select(makeSelectIDBProps());

  const idb = yield G.createIndexedDB(idbProps, onIDBCloseResolved);

  yield put(A.initTableOrderIDBSuccess(idb));
  yield spawn(onCloseDatabase, onClosePromise);
}

function* getReportTableOrder({ payload: reportGuid }: Object) {
  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());

  const result = yield G.readDataFromIDB({
    idb,
    idbProps,
    credentials: reportGuid,
  });

  const orderTable = R.pathOr([], ['value'], result);

  yield put(A.setReportTableOrder({reportGuid, orderTable}));
}

function* updateTableOrderByGuid({ payload: dataToUpdate }: Object) {
  const { reportGuid, orderTable } = dataToUpdate;

  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());

  yield G.setDataToIDB({
    idb,
    idbProps,
    dataToSet: orderTable,
    credentials: reportGuid,
  });

  yield put(A.setReportTableOrder({reportGuid, orderTable}));
}

function* deleteAllOrderData() {
  const idb = yield select(makeSelectIDB());
  const idbProps = yield select(makeSelectIDBProps());

  yield G.deleteDatabase({
    idb,
    idbProps,
  });

  yield put(A.reportTableOrderIDBReconnect());
  yield put(A.setReportTableOrder({}));
}

export default function* orderTableWatcherSaga() {
  yield takeLatest(A.getReportTableOrder, getReportTableOrder);
  yield takeLatest(A.deleteAllReportOrderData, deleteAllOrderData);
  yield takeLatest(A.updateTableOrderByGuid, updateTableOrderByGuid);
  yield takeLatest(A.reportTableOrderIDBReconnect, initTableOrderIDB);
}
