import L from 'leaflet';
import * as R from 'ramda';
import 'leaflet-routing-machine/src';
import { useState, useEffect } from 'react';
import { withLeaflet } from 'react-leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// component leaflet-map
import './lrm-tomtom';
//////////////////////////////////////////////////

export const Routing = withLeaflet((props: Object) => {
  const { color, waypoints, withDynamicWaypoints, leaflet: { map } } = props;

  const [routingControl, setRoutingControl] = useState();

  const isBorderOpen = G.getAmousConfigByNameFromWindow(GC.GENERAL_DC_BORDER_OPEN);

  useEffect(() => {
    setRoutingControl(L.Routing.control({
      waypoints,
      show: false,
      addWaypoints: false,
      createMarker: () => null,
      draggableWaypoints: false,
      router: new L.Routing.TomTom(
        'nU5hRXsrk8BBbK0wY330P1HzIY3S3uTT',
        { avoid: G.ifElse((G.isFalse(isBorderOpen)), 'borderCrossings', '') },
      ),
      lineOptions: {
        styles: [
          {
            weight: 5,
            opacity: 0.8,
            color: R.or(color, G.getTheme('colors.navyBlue')),
          },
        ],
      },
    }).addTo(map));
  }, [map]);

  useEffect(() => {
    if (R.not(withDynamicWaypoints)) return;

    routingControl?.setWaypoints(waypoints);
  }, [waypoints, routingControl, withDynamicWaypoints]);

  return null;
});
