import React from 'react';
import * as R from 'ramda';
import { PopperComponent } from '../popper';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex } from '../../ui';
// components/color-picker
import ColorPicker from './';
//////////////////////////////////////////////////

const blackColor = G.getTheme('colors.black');
const whiteColor = G.getTheme('colors.white');
const darkGreyColor = G.getTheme('colors.darkGrey');

const defaultActiveBoxProps = {
  mr: '3px',
  width: 16,
  height: 16,
  opacity: 0.9,
  borderRadius: '4px',
};

const defaultPopperProps = {
  rotate: false,
  type: 'click',
  minWidth: 120,
  position: 'bottom',
  borderWidth: '0px',
  popperBorderRadius: '8px',
  wrapperWidth: 'max-content',
  popperBoxShadow: '0px 2px 12px 0px #0000001F',
};

const PopperColorPicker = (props: Object) => {
  const { arrowColor, pickerProps, popperProps, activeBorder, activeBoxProps } = props;

  const value = R.path(['value'], pickerProps);

  const _popperProps = R.mergeRight(defaultPopperProps, popperProps);

  const _activeBoxProps = R.mergeRight(defaultActiveBoxProps, activeBoxProps);

  const getBorder = () => {
    if (G.isNotNilAndNotEmpty(activeBorder)) return activeBorder;

    return `1px solid ${blackColor}`;
  };

  const isPopperTypeClick = R.equals('click', G.getPropFromObject(GC.FIELD_TYPE, _popperProps));

  const content = ({ closePopper }: Object = {}) => (
    <Flex p={10} bg={whiteColor} borderRadius='8px' alignItems='start' flexDirection='column'>
      <Box
        mb={10}
        fontSize={12}
        color={darkGreyColor}
      >
        {G.getWindowLocale('titles:select-color', 'Select Color')}
      </Box>
      <ColorPicker {...pickerProps} closePopper={closePopper} shouldClosePopperOnSelect={isPopperTypeClick} />
    </Flex>
  );

  return (
    <Flex mr='5px' data-testid='testid-PopperColorPicker'>
      <PopperComponent
        {..._popperProps}
        content={isPopperTypeClick ? content : content()}
      >
        <Flex>
          <Box
            {..._activeBoxProps}
            title={value}
            background={value}
            border={getBorder()}
          />
          {I.arrowDownSimple(arrowColor, 8, 8)}
        </Flex>
      </PopperComponent>
    </Flex>
  );
};

PopperColorPicker.defaultProps = {
  pickerProps: {},
  popperProps: {},
  activeBoxProps: {},
};

export default PopperColorPicker;
