import React from 'react';
import * as R from 'ramda';
import FileSaver from 'file-saver';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { Title, Header } from '../modal/ui';
import { LocalLoader } from '../local-loader';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import * as I from '../../svgs';
// ui
import { Box, Flex, Iframe } from '../../ui';
// utilities
import { sendRequestWithQSParamsSerializer } from '../../utilities/http';
// component document-preview
import { FileViewerWrapper } from './ui';
import DocumentView from './document-view';
import { withAsyncFileOnDidMount } from './with-async-file';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.darkBlue');
const mainLightColor = G.getTheme('colors.light.mainLight');

const enhanceAsync = compose(
  withAsyncFileOnDidMount,
  withHandlers({
    handleDownload: (props: Object) => () => (
      FileSaver.saveAs(
        props.asyncInitialData.data,
        R.path(['asyncOptions', 'params', GC.FIELD_FILE_NAME], props),
      )
    ),
  }),
  pure,
);

const HeaderComponent = (props: Object) => {
  const { title, closeModal, handleDownload } = props;

  return (
    <Header height={32} backgroundColor={darkBlueColor}>
      <Title>{title}</Title>
      <Flex gap={20} mr={12}>
        <Flex
          cursor='pointer'
          onClick={handleDownload}
          title={G.getWindowLocale('actions:download', 'Download')}
        >
          {I.downloadDocument(mainLightColor, 20, 20)}
        </Flex>
        <Flex
          cursor='pointer'
          onClick={closeModal}
          title={G.getWindowLocale('actions:close', 'Close')}
        >
          {I.closeIcon(mainLightColor, 14, 14)}
        </Flex>
      </Flex>
    </Header>
  );
};

const excelExts = [GC.EXTENSION_XLS, GC.EXTENSION_XLSX];

const imageExts = [
  GC.EXTENSION_PNG, GC.EXTENSION_GIF, GC.EXTENSION_JPEG, GC.EXTENSION_JPG,
];

const DocumentPreview = (props: Object) => {
  const {
    title,
    fileType,
    closeModal,
    handleDownload,
    filePublicLink,
    asyncInitialData = {
      loading: false,
    },
  } = props;

  const { data, loading } = asyncInitialData;

  let url = null;

  if (G.isNotNilAndNotEmpty(data)) url = window.URL.createObjectURL(data);

  if (G.isNotNilAndNotEmpty(filePublicLink)) url = filePublicLink;

  const fileNotAvailable = G.getWindowLocale('messages:file-not-available', 'The file is not available');

  return (
    <Box width='100%' height='100vh'>
      <HeaderComponent
        title={title}
        closeModal={closeModal}
        handleDownload={handleDownload}
      />
      <LocalLoader localLoaderOpen={R.and(loading, R.isNil(url))}>
        <Box width='100%' overflow='auto' position='static' height='calc(100vh - 32px)'>
          { R.includes(fileType, excelExts) && (
            <FileViewerWrapper p='10px 4px' disableScroll={true}>
              <DocumentView filePath={url} fileType={fileType} />
            </FileViewerWrapper>
          )}
          { R.includes(fileType, imageExts) && (
            <FileViewerWrapper p='10px 4px' disableScroll={true}>
              <img
                src={url}
                width='100%'
                height='100%'
                alt={fileNotAvailable}
                style={{ objectFit: 'contain' }}
              />
            </FileViewerWrapper>
          )}
          { R.and(
            G.notContain(fileType, excelExts),
            G.notContain(fileType, imageExts),
          ) && (
            <FileViewerWrapper>
              <Iframe type={fileType} src={url} />
            </FileViewerWrapper>
          )}
        </Box>
      </LocalLoader>
    </Box>
  );
};

const AsyncDocumentPreview = enhanceAsync(DocumentPreview);

export const withAsyncDocumentPreviewDrawer = compose(
  withHandlers({
    handleDownloadFile: () => async ({ endpoint, documentFilename }: Object) => {
      const options = {
        resType: 'arraybuffer',
      };

      const res = await sendRequestWithQSParamsSerializer('get', endpoint, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.saveFileFromResponse(res, documentFilename);
      } else {
        G.handleFailResponseSimple(res);
      }
    },
  }),
  withHandlers({
    handleOpenFilePreview: ({
      openModal,
      closeModal,
      onClickCallback,
      handleDownloadFile,
      getDownloadDocumentEndpoint,
    }: Object) => (document: Object) => {
      const { endpoint, documentFilename, primaryObjectGuid, filePublicLink } = document;

      const fileType = R.last(R.split('.', documentFilename));

      let modal;
      let component;

      if (G.notContain(fileType, GC.FILE_PREVIEW_SUPPORTED_EXTENSIONS)) {
        component = (
          <Box fontSize={14}>
            {
              G.getWindowLocale(
                'messages:file-not-supported-for-preview',
                'Sorry, file is not supported for preview',
              )
            }
          </Box>
        );

        modal = {
          p: 15,
          component,
          options: {
            width: 400,
            outsideCloseButton: true,
          },
        };
      } else {
        if (R.and(
          G.notContain(fileType, excelExts),
          G.isNotNilAndNotEmpty(filePublicLink),
        )) {
          component = (
            <DocumentPreview
              fileType={fileType}
              closeModal={closeModal}
              title={documentFilename}
              filePublicLink={filePublicLink}
              handleDownload={() => handleDownloadFile({ endpoint, documentFilename })}
            />
          );
        } else {
          const data = {
            primaryObjectGuid,
            [GC.FIELD_FILE_NAME]: documentFilename,
          };

          component = (
            <AsyncDocumentPreview
              fileType={fileType}
              closeModal={closeModal}
              title={documentFilename}
              asyncOptions={{ params: data, resType: 'arraybuffer' }}
              asyncEndpoint={endpoint || getDownloadDocumentEndpoint(document)}
            />
          );
        }

        modal = {
          component,
          fixedWidth: true,
          isExpandedContainer: true,
          wrapperStyles: { width: 1090, maxWidth: 'calc(100vw - 60px);' },
          options: {
            minWidth: 600,
            minHeight: '100vh',
            withCloseIcon: true,
            default: { x: 0, y: 0, width: 1090, height: '100vh', maxWidth: 'calc(100vw - 60px);' },
            style: {
              borderLeft: '2px solid white',
              backgroundColor: G.getTheme('colors.bgGrey'),
            },
          },
        };
      }

      if (G.isFunction(onClickCallback)) onClickCallback();

      openModal(modal);
    },
    pure,
  }),
);
