import React from 'react';
import * as R from 'ramda';
// components
import { ActionBox } from '../action-box';
// helpers/constants
import * as G from '../../helpers';
// hocs
import { withSetExpandedContainerOptions } from '../../hocs';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const OpenExpandedContainer = withSetExpandedContainerOptions((props: Object) => {
  const {
    text,
    guid,
    permissions,
    componentType,
    handleSetExpandedContainerOptions,
  } = props;

  const condition = R.or(
    G.isNilOrEmpty(guid),
    R.and(G.isNotNilAndNotEmpty(permissions), G.hasNotAmousCurrentUserPermissions(permissions)),
  );

  if (condition) return <Box>{text}</Box>;

  const action = () => handleSetExpandedContainerOptions({
    opened: true,
    componentType,
    visitPageGuid: guid,
  });

  return (
    <ActionBox
      text={text}
      action={action}
    />
  );
});

export default OpenExpandedContainer;
