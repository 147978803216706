import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  pure,
  branch,
  compose,
  withState,
  renderComponent,
} from 'react-recompose';
// components
import CommonModal from './modal';
import LoaderComponent from './loader';
import AudioPlayer from './audio-player';
import { makeSelectModal } from './modal/selectors';
import { makeSelectLoader } from './loader/selectors';
// features
import SidebarComponent from '../features/menu';
import NavbarComponent from '../features/navbar-menu';
import userAuthHoc from '../features/auth/user-auth-hoc';
import ExpandedContainer from '../features/expanded-container';
import { makeSelectStyling } from '../features/styling/selectors';
import { makeSelectBranchesTree } from '../features/branch/selectors';
import { makeSelectExpandedContainerOptions } from '../features/expanded-container/selectors';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
import { makeSelectInitialDataLoaded } from '../common/selectors';
// ui
import { LayoutWrapper } from '../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withState('expandedContainerZIndex', 'setExpandedContainerZIndex', 11),
  userAuthHoc,
  branch(
    (props: Object) => R.not(props.initialDataLoaded),
    renderComponent(LoaderComponent),
  ),
  pure,
);

export const MainLayout = (props: Object) => (
  <LayoutWrapper data-testid='testid-LayoutWrapper'>
    {props.useNavbarMenu && <NavbarComponent {...props} />}
    {R.not(props.useNavbarMenu) && <SidebarComponent {...props} />}
    {
      props.expandedContainerOptions.opened &&
      <ExpandedContainer
        modalOpened={props.modal.isOpened}
        zIndex={props.expandedContainerZIndex}
        setZIndex={props.setExpandedContainerZIndex}
      />
    }
    {
      props.modal.isOpened &&
      props.modal.modal.map((modal: Object, index: number) => (
        <CommonModal
          key={index}
          index={index}
          params={{ ...props.modal, modal }}
        />
      ))
    }
    {
      props.loader.isOpened &&
      <LoaderComponent />
    }
    <AudioPlayer />
  </LayoutWrapper>
);

const mapStateToProps = (state: Object) => createStructuredSelector({
  modal: makeSelectModal(state),
  loader: makeSelectLoader(state),
  styling: makeSelectStyling(state),
  rootBranch: makeSelectBranchesTree(state),
  initialDataLoaded: makeSelectInitialDataLoaded(state),
  expandedContainerOptions: makeSelectExpandedContainerOptions(state),
  useNavbarMenu: () => G.isTrue(G.getAmousConfigByNameFromWindow(GC.UI_USE_MENU_VERSION_2)),
});

export default connect(mapStateToProps)(enhance(MainLayout));
