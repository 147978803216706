import L from 'leaflet';
import * as R from 'ramda';
import { css } from 'styled-components';
import 'leaflet-draw/dist/leaflet.draw.css';
import ReactDOMServer from 'react-dom/server';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Map, Popup, Marker, TileLayer } from 'react-leaflet';
import React, { memo, useRef, Fragment, useEffect, useCallback } from 'react';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Flex } from '../../ui';
//////////////////////////////////////////////////

const getMapCenterAndBounds = (locations: Array = []) => {
  if (G.isNilOrEmpty(locations)) return { center: [38.755157, -98.269035] };

  const bounds = R.map(({ latLng }: Object) => R.values(latLng), locations);
  const polygon = L.polygon(bounds);

  return {
    bounds,
    center: polygon.getBounds().getCenter(),
  };
};

const blueColor = G.getTheme('colors.light.blue');

const getMarkerIcon = ({ location, markerIcon, markerIconColor }: Object) => {
  const { markerContent } = location;

  const iconColor = R.or(markerIconColor, blueColor);

  const marker = G.isNotNil(markerContent) ? markerContent : (
    <Flex
      width='100%'
      height='100%'
      border='2px solid'
      borderRadius='50%'
      justifyContent='center'
      borderColor={iconColor}
    >
      {markerIcon(iconColor, '100%', '100%')}
    </Flex>
  );

  return new L.DivIcon({
    className: 'custom-marker',
    html: ReactDOMServer.renderToString(marker),
    iconSize: L.point(...G.ifElse(true, [45, 45], [30, 30]), true),
  });
};

const CustomMarker = (props: Object) => {
  const { location, markerIcon, markerIconColor } = props;
  const { infoContent } = location;

  return (
    <Marker location={location} position={location.latLng} icon={getMarkerIcon({ location, markerIcon, markerIconColor })}>
      {
        G.isNotNil(infoContent) &&
        <Popup>{infoContent()}</Popup>
      }
    </Marker>
  );
};

const fleetIconCreateFunction = (cluster: Object) => {
  const markers = cluster.getAllChildMarkers();

  const count = markers.reduce((sum, marker) => {
    const count = marker?.options?.location?.count || 0;

    return sum + count;
  }, 0);

  let clusterSize = 'small';

  if (R.gt(count, 10)) clusterSize = 'medium';

  if (R.gt(count, 100)) clusterSize = 'large';

  return L.divIcon({
    iconSize: L.point(40, 40, true),
    className: `${`marker-cluster marker-cluster-${clusterSize}`}`,
    html: `<div class='animated-clustering-group'><span>${count}</span></div>`,
  });
};

const LeafletMap = memo((props: Object) => {
  const {
    width,
    height,
    mapType,
    locations,
    markerIcon,
    handleSetZoom,
    markerIconColor,
    withoutClustering,
    childMapComponent,
  } = props;

  const { center, bounds } = getMapCenterAndBounds(locations);

  const MarkersWrapper = withoutClustering ? Fragment : MarkerClusterGroup;

  const iconCreateFunction = R.and(R.not(withoutClustering), R.equals(mapType, 'fleet')) ?
    fleetIconCreateFunction : null;

  const mapRef = useRef();

  const preventZoomOnShift = useCallback((event: Event) => {
    if (R.or(event.shiftKey, event.ctrlKey)) event.stopPropagation();
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.leafletElement._container.addEventListener('wheel', preventZoomOnShift, true);
    }

    return () => mapRef.current?.leafletElement._container.removeEventListener;
  }, [mapRef.current, preventZoomOnShift]);

  useEffect(() => {
    if (R.not(G.isFunction(handleSetZoom))) return;

    const map = mapRef.current.leafletElement;

    const handleZoomEnd = () => {
      const currentZoom = map.getZoom();

      handleSetZoom(currentZoom);
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [handleSetZoom]);

  return (
    <Map
      zoom={4}
      maxZoom={18}
      ref={mapRef}
      bounds={bounds}
      center={center}
      css={css`height: ${height};width: ${width};z-index: 1`}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {
        G.isNotNilAndNotEmpty(locations) &&
        <MarkersWrapper iconCreateFunction={iconCreateFunction}>
          {
            locations.map((location: Object, i: number) => (
              <CustomMarker key={i} location={location} markerIcon={markerIcon} markerIconColor={markerIconColor} />
            ))
          }
        </MarkersWrapper>
      }
      { G.isNotNilAndNotEmpty(childMapComponent) && childMapComponent }
    </Map>
  );
});

export default LeafletMap;
