import * as R from 'ramda';
import styled, { keyframes } from 'styled-components';
import { space, width, height, layout, zIndex, maxWidth } from 'styled-system';
// helpers/constants
import * as G from '../../helpers';
// ui
import { AbsoluteBox } from '../../ui';
//////////////////////////////////////////////////

export const animation = (props: Object) => {
  const from = props.params.position.split('-')[0];
  const moves = {
    top: keyframes`
      0% { top: -${props.params.height}px }
      100% { top: 0 }
    `,
    bottom: keyframes`
      0% { bottom: -${props.params.height}px }
      100% { bottom: 0 }
    `,
  };
  return moves[from];
};

export const ModalWrapper = styled.div`
  ${maxWidth}

  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(53, 53, 53, 0.5);

  z-index: ${({ index = 0 }: Object) => R.add(index, 1300)};
`;

export const ModalWrapperWithZIndex = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(53, 53, 53, 0.5);
  z-index: ${({ zIndex }: Object) => zIndex || 11};
`;

export const Title = styled.span`
`;

export const ModalContentWrapper = styled.div`
  ${space}
  ${layout}
  ${zIndex}

  overflow: ${({ withBorderRadius }: Object) => withBorderRadius && 'hidden'};
  background-color: ${({ params }: Object) => params.backgroundColor || G.getTheme('colors.white')};
  box-shadow: 0 0 8px 1px ${({ params }: Object) => params.boxShadow || G.getTheme('colors.mainBlue')};
  border-radius: ${({ withBorderRadius }: Object) => G.ifElse(withBorderRadius, '0 11px 10px 10px', '2px')};
  border-top-left-radius: ${({ isDraggable }: Object) => isDraggable && 0};
`;

ModalContentWrapper.defaultProps = {
  overflow: 'initial',
};

export const Header = styled.div`
  ${space}
  ${width}
  ${height}

  width: 100%;
  line-height: 30px;
  font-weight: bold;
  padding-left: 15px;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  color: ${() => G.getTheme('forms.group.title.textColor')};
  background-color: ${({ backgroundColor }: Object) => backgroundColor};
  border-radius: ${({ withBorderRadius }: Object) => withBorderRadius && '0 10px 0 0'};
`;

export const Footer = styled.div`
  right: 0;
  width: 100%;
  display: flex;
  text-align: center;
  padding: ${({ p }: Object) => p || '15px'};
  bottom: ${({ bottom }: Object) => bottom || 0};
  z-index: ${({ zIndex }: Object) => zIndex || 9};
  position: ${({ position }: Object) => position || 'absolute'};
  justify-content: ${({ justifyContent }: Object) => justifyContent || 'space-between'};
`;

export const MoveHandler = styled(AbsoluteBox)`
  background-color: ${() => G.getTheme('forms.group.title.bgColor')};
`;
