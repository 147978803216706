import styled from 'styled-components';
import { space } from 'styled-system';
import { renderToString } from 'react-dom/server';
// svgs
import { zoomPlus, zoomMinus } from '../../svgs';
//////////////////////////////////////////////////

const encodePlusIcon = encodeURIComponent(renderToString(zoomPlus()));
const encodeMinusIcon = encodeURIComponent(renderToString(zoomMinus()));

export const FileViewerWrapper = styled.div`
  ${space}

  height: 100%;
  overflow: hidden;

  & .pg-viewer-wrapper {
    overflow: ${({ disableScroll }: Object) => (disableScroll ? 'hidden' : 'auto')};
  }

  & .react-grid-Container {
    height: 100%;
    margin-left: 0;
  }

  & .pdf-controlls-container {
    width: 80px;
    height: 40px;
    margin: auto;
    display: flex;
    font-size: 30px;
    align-items: flex-start;
    justify-content: space-between;
  }

  & .view-control:nth-child(odd) {
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
  }

  & .view-control:nth-child(1) {
    background-image: url('data:image/svg+xml;utf8,${encodePlusIcon}');
  }

  & .view-control:nth-child(3) {
    background-image: url('data:image/svg+xml;utf8,${encodeMinusIcon}');
  }

  & .photo-viewer-container {
    width: auto!important;
    height: auto!important;

    & img {
      width: 100%!important;
      height: auto!important;
    }
  }
`;
