import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pure, compose, lifecycle, withHandlers } from 'react-recompose';
// components
import { Label } from '../label';
import Resizable from '../resizable';
// hocs
import { withDraggableHOC } from '../../hocs';
// helpers/constants
import * as G from '../../helpers';
// icons
import * as I from '../../svgs';
import * as GC from '../../constants';
// ui
import {
  Box,
  FixedBox,
  AbsoluteBox,
  ModalCancelButton,
  ModalActionButton,
} from '../../ui';
// components common/modal
import { closeModal } from './actions';
import { Title, Footer, Header, ModalWrapper, ModalContentWrapper } from './ui';
//////////////////////////////////////////////////

const darkBlueColor = G.getTheme('colors.dark.darkBlue');
const mainLightColor = G.getTheme('colors.light.mainLight');

const enhance = compose(
  withHandlers({
    handleCloseModal: ({ closeModal }: Object) => ({ keyCode }: Object) => {
      if (R.equals(keyCode, GC.EVENT_KEY_CODE_ESC)) closeModal();
    },
  }),
  lifecycle({
    componentDidMount() {
      document.addEventListener('keyup', this.props.handleCloseModal);
    },
    componentWillUnmount() {
      document.removeEventListener('keyup', this.props.handleCloseModal);
    },
  }),
  pure,
);

const HeaderComponent = (props: Object) => {
  const { title, closeIcon, closeAction, withBorderRadius } = props;

  return (
    <Header
      backgroundColor={darkBlueColor}
      withBorderRadius={withBorderRadius}
    >
      <Title>{title}</Title>
      {
        G.isTrue(closeIcon) &&
        <a onClick={closeAction}>
          <Label endLabel='shape' />
        </a>
      }
    </Header>
  );
};

const FooterComponent = (props: Object) => {
  const {
    buttons,
    btnWidth,
    footerJC,
    closeAction,
    footerBottom,
    footerZIndex,
    footerPadding,
    footerPosition,
  } = props;

  const getBtnMargin = (margin: string) => {
    if (R.or(G.isNilOrEmpty(footerJC), R.equals(footerJC, 'space-between'))) {
      return '0px';
    }

    return margin;
  };

  return (
    <Footer
      p={footerPadding}
      zIndex={footerZIndex}
      bottom={footerBottom}
      position={footerPosition}
      justifyContent={footerJC}
    >
      <ModalCancelButton
        zIndex={footerZIndex}
        width={R.or(btnWidth, 120)}
        onClick={(event: Object) => closeAction(event)}
      >
        {G.getWindowLocale('actions:cancel', 'Cancel')}
      </ModalCancelButton>
      {
        buttons.map(({ id, name, margin, action }: Object, i: number) => (
          <ModalActionButton
            key={i}
            form={id}
            type='submit'
            onClick={action}
            zIndex={footerZIndex}
            m={getBtnMargin(margin)}
            width={R.or(btnWidth, 120)}
          >
            {name}
          </ModalActionButton>
        ))
      }
    </Footer>
  );
};

export const ModalContent = ({ p, index, options, component }: Object) => {
  const {
    title,
    version,
    btnWidth,
    footerJC,
    closeAction,
    controlButtons,
    withBorderRadius,
  } = options;

  return (
    <ModalContentWrapper
      zIndex={1}
      index={index}
      withBorderRadius={withBorderRadius}
      params={R.pick(['boxShadow', 'backgroundColor'], R.or(options, {}))}
      {...R.pick(['width', 'height', 'maxWidth', 'minWidth', 'overflow', 'minHeight', 'maxHeight'], options)}
    >
      {
        G.isNotNilAndNotEmpty(title) &&
        <HeaderComponent
          title={title}
          version={version}
          closeAction={closeAction}
          withBorderRadius={withBorderRadius}
        />
      }
      <Box p={R.or(p, '15px 15px 70px 15px')}>
        {component}
        {
          G.isNotNilAndNotEmpty(controlButtons) &&
          <FooterComponent
            btnWidth={btnWidth}
            footerJC={footerJC}
            buttons={controlButtons}
            closeAction={closeAction}
          />
        }
      </Box>
    </ModalContentWrapper>
  );
};

const Content = (props: Object) => {
  const { index, params, closeModal, isDraggable } = props;

  const {
    p,
    options,
    component,
    TitleComponent,
  } = R.pathOr({}, ['modal'], params);

  const {
    title,
    version,
    footerJC,
    btnWidth,
    closeAction,
    footerZIndex,
    footerBottom,
    footerPadding,
    footerPosition,
    controlButtons,
    withBorderRadius,
    componentWrapperHeight,
  } = R.pathOr({}, ['modal', 'options'], params);

  const closingModal = (params: Object) => {
    if (G.isNotNil(closeAction)) closeAction(params);

    closeModal();
  };

  return (
    <ModalContentWrapper
      zIndex={1}
      index={index}
      isDraggable={isDraggable}
      withBorderRadius={withBorderRadius}
      params={R.pick(['boxShadow', 'backgroundColor'], R.or(options, {}))}
      {...R.pick(['width', 'height', 'maxWidth', 'minWidth', 'overflow', 'minHeight', 'maxHeight'], options)}
    >
      {
        G.isNotNilAndNotEmpty(TitleComponent) &&
        <TitleComponent />
      }
      {
        G.isNotNilAndNotEmpty(title) &&
        <HeaderComponent
          title={title}
          version={version}
          closeAction={closingModal}
          withBorderRadius={withBorderRadius}
        />
      }
      <Box height={componentWrapperHeight} p={R.or(p, '15px 15px 70px 15px')}>
        {component}
        {
          G.isNotNilAndNotEmpty(controlButtons) &&
          <FooterComponent
            footerJC={footerJC}
            btnWidth={btnWidth}
            buttons={controlButtons}
            closeAction={closingModal}
            footerZIndex={footerZIndex}
            footerBottom={footerBottom}
            footerPadding={footerPadding}
            footerPosition={footerPosition}
          />
        }
      </Box>
    </ModalContentWrapper>
  );
};

const ModalWithDraggable = withDraggableHOC((props: Object) => {
  const {
    top,
    left,
    index,
    maxWidth,
    closeModal,
    initialWidth,
    initialHeight,
    withCloseIcon,
    handleMouseDown,
    outsideCloseButton,
    handleRegisterChild,
  } = props;

  return (
    <ModalWrapper index={index} maxWidth={maxWidth}>
      <AbsoluteBox
        top={top}
        zIndex={12}
        left={left}
        width={initialWidth}
        // TODO: uncomment if draggable doesn't work
        height={initialHeight}
        ref={(ref: Object) => handleRegisterChild(ref, 'movableContainer')}
      >
        <AbsoluteBox
          p='5px'
          top={-30}
          left='0px'
          width={70}
          zIndex={11}
          height={30}
          cursor='move'
          bg={darkBlueColor}
          flexDirection='column'
          borderTopLeftRadius={15}
          borderTopRightRadius={15}
          onMouseDown={handleMouseDown}
        >
          {I.moveIcon(G.getTheme('colors.white'), 22, 23)}
        </AbsoluteBox>
        {
          outsideCloseButton &&
          <AbsoluteBox
            width={36}
            right='0px'
            height={30}
            display='flex'
            cursor='pointer'
            bg={darkBlueColor}
            onClick={closeModal}
            justifyContent='center'
            borderTopLeftRadius={12}
            borderTopRightRadius={12}
            bottom='calc(100% - 2px)'
          >
            {I.closeIcon(mainLightColor)}
          </AbsoluteBox>
        }
        {
          withCloseIcon &&
          <AbsoluteBox
            top={10}
            right={15}
            zIndex={14}
            cursor='pointer'
            onClick={closeModal}
          >
            {I.closeIcon(mainLightColor)}
          </AbsoluteBox>
        }
        <Content {...props} isDraggable={true} />
      </AbsoluteBox>
    </ModalWrapper>
  );
});


const Component = (props: Object) => {
  const {
    index,
    params,
    maxWidth,
    closeModal,
  } = props;

  if (R.path(['modal', 'isExpandedContainer'], params)) {
    const {
      modal: {
        title,
        options,
        component,
        fixedWidth,
        wrapperStyles,
      },
    } = params;

    const hasTitle = G.isNotNilAndNotEmpty(title);
    const heightSubtract = G.ifElse(hasTitle, 30, 0);
    const widthSubtract = G.ifElse(fixedWidth, 0, 25);

    return (
      <FixedBox
        top='0'
        width='100%'
        height='100%'
        zIndex={R.add(index, 1300)}
        background={G.getTheme('reactModal.overlayBgColor')}
      >
        <Resizable
          options={options}
          wrapperStyles={wrapperStyles}
        >
          {hasTitle && <HeaderComponent title={title} />}
          <Box
            overflow='auto'
            ml={G.ifElse(fixedWidth, 0, 25)}
            width={`calc(100% - ${widthSubtract}px)`}
            height={`calc(100vh - ${heightSubtract}px)`}
          >
            {component}
          </Box>
        </Resizable>
      </FixedBox>
    );
  }

  const version = R.path(['params', 'modal', 'options', 'version'], props);
  const withCloseIcon = R.pathOr(false, ['params', 'modal', 'options', 'withCloseIcon'], props);
  const outsideCloseButton = R.pathOr(false, ['params', 'modal', 'options', 'outsideCloseButton'], props);

  if (G.isFalse(R.path(['params', 'modal', 'options', 'movable'], props))) {
    return (
      <ModalWrapper index={index} maxWidth={maxWidth}>
        <AbsoluteBox
          width={R.path(['params', 'modal', 'options', 'width'], props)}
          height={R.path(['params', 'modal', 'options', 'height'], props)}
          top={R.pathOr('initial', ['params', 'modal', 'options', 'top'], props)}
          left={R.pathOr('initial', ['params', 'modal', 'options', 'left'], props)}
          right={R.pathOr('initial', ['params', 'modal', 'options', 'right'], props)}
        >
          <Content {...props} version={version} />
          {
            outsideCloseButton &&
            <AbsoluteBox
              width={36}
              right='0px'
              height={30}
              zIndex={11}
              bottom='100%'
              display='flex'
              cursor='pointer'
              bg={darkBlueColor}
              borderTRRadius={12}
              borderTLRadius={12}
              onClick={closeModal}
              justifyContent='center'
              borderTopLeftRadius={12}
              borderTopRightRadius={12}
            >
              {I.closeIcon(mainLightColor)}
            </AbsoluteBox>
          }
          {
            withCloseIcon &&
            <AbsoluteBox
              top='7px'
              right={15}
              zIndex={14}
              cursor='pointer'
              onClick={closeModal}
            >
              {I.closeIcon(mainLightColor, 15, 15)}
            </AbsoluteBox>
          }
        </AbsoluteBox>
      </ModalWrapper>
    );
  }

  return (
    <ModalWithDraggable
      {...props}
      version={version}
      withCloseIcon={withCloseIcon}
      outsideCloseButton={outsideCloseButton}
      initialWidth={R.path(['params', 'modal', 'options', 'width'], props)}
      initialHeight={R.path(['params', 'modal', 'options', 'height'], props)}
      top={R.pathOr('initial', ['params', 'modal', 'options', 'top'], props)}
      left={R.pathOr('initial', ['params', 'modal', 'options', 'left'], props)}
    />
  );
};

export const ModalComponent = connect(null, {
  closeModal,
})(enhance(Component));

export default ModalComponent;
