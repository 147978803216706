import React from 'react';
import * as R from 'ramda';
// helpers/constants
import * as G from '../../helpers';
// ui
import { Box, Flex } from '../../ui';
//////////////////////////////////////////////////

// picker default colors
const pickerColorGrey = G.getTheme('colors.#EDEFF1');
const pickerColorRed = G.getTheme('colors.#E15141');
const pickerColorBlue = G.getTheme('colors.#4896EB');
const pickerColorGreen = G.getTheme('colors.#67AC5B');
const pickerColorYellow = G.getTheme('colors.#F4C344');

const colors = [
  pickerColorGrey,
  pickerColorRed,
  pickerColorBlue,
  pickerColorGreen,
  pickerColorYellow,
];

const ColorPicker = (props: Object) => {
  const {
    value,
    colors,
    fieldName,
    itemStyles,
    closePopper,
    activeBorder,
    setFieldValue,
    wrapperStyles,
    changeColorCallback,
    shouldTransformActive,
    shouldClosePopperOnSelect,
  } = props;

  const onChangeColor = (color: string, value: string) => {
    const valueToUse = G.ifElse(R.equals(color, value), null, color);

    G.callFunctionWithArgs(changeColorCallback, valueToUse);

    G.callFunctionWith2Args(setFieldValue, fieldName, valueToUse);

    if (G.isTrue(shouldClosePopperOnSelect)) G.callFunction(closePopper);
  };

  const _itemStyles = R.mergeRight(
    {
      width: 36,
      height: 36,
      borderRadius: '8px',
    },
    itemStyles,
  );

  const getBorder = (item: string, activeBorder: string) => {
    if (G.notEquals(item, value)) return null;

    if (G.isNotNilAndNotEmpty(activeBorder)) return activeBorder;

    return `2px solid ${G.getTheme('colors.#2B5293')}`;
  };

  return (
    <Flex {...wrapperStyles}>
      {
        colors.map((item: string, index: number) => (
          <Box
            key={`${index}${item}`}
            {..._itemStyles}
            title={item}
            cursor='pointer'
            background={item}
            active={R.equals(item, value)}
            border={getBorder(item, activeBorder)}
            onClick={() => onChangeColor(item, value)}
            transition={G.ifElse(shouldTransformActive, 'transform .2s linear', null)}
            transform={G.ifElse(R.and(shouldTransformActive, R.equals(item, value)), 'scale(1.25)', null)}
          />
        ))
      }
    </Flex>
  );
};

ColorPicker.defaultProps = {
  colors,
  itemStyles: {},
  wrapperStyles: {
    justifyContent: 'space-between',
  },
};

export {
  colors,
  pickerColorRed,
  pickerColorGrey,
  pickerColorBlue,
  pickerColorGreen,
  pickerColorYellow,
};

export default ColorPicker;
