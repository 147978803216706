import React from 'react';
import FileViewer from 'react-file-viewer';
import { pure, compose, withHandlers } from 'react-recompose';
// ui
import { Box } from '../../ui';
//////////////////////////////////////////////////

const enhance = compose(
  withHandlers({
    handleError: () => (e: Object) => console.log(e, 'FILE PREVIEW ERROR'),
  }),
  pure,
);

const DocumentView = enhance((props: Object) => (
  <Box height='100%'>
    <FileViewer
      height='100%'
      fileType={props.fileType}
      filePath={props.filePath}
      onError={props.handleError}
    />
  </Box>
));

export default DocumentView;
